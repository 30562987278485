












































































































































































































































import Vue, { VueConstructor } from 'vue';
import { Option, Select } from 'element-ui';
import PriceInput from '@/components/Forms/PriceInput.vue';
import PercentInput from '@/components/Forms/PercentInput.vue';
import RangeInput from '@/components/Forms/RangeInput.vue';
import ItemDetail from './ItemDetail.vue';
import FripTable from '@/components/FripComponents/FripTable.vue';
import { ProductItemStatusChangeService } from '../../service/ProductItemStatuschangeService';
import { getItemStatusLabel } from '../../util/getItemStatusLabel';
import {
  ItemOptionParam,
  ItemParam,
} from '../../model/product/param/productSaleInfoParam';
import {
  CurrencyCode,
  ItemState,
  PriceOperator,
  PriceType,
  QuotaState,
  SalesType,
} from '../../model/product/enum';
import { priceTypeOptions } from '../../constants/productCreateOptions';
import { isHostAdmin } from '@/env';
import { Option as ColumnOption } from '@/common/Option';
import { apolloClient } from '@/apolloClient';
import { StatusLabel } from '@/common/model/statusLabel';

const productItemStatusChangeService = new ProductItemStatusChangeService(
  apolloClient
);

interface ItemPrice {
  type: PriceType;
  value: number;
  operator: PriceOperator;
}

interface ItemListData {
  rows: ItemParam[];
  checkedRows: ItemParam[];
  isAdmin: boolean;
  optionDetailModal: boolean;
  optionPriceChangeModal: boolean;
  representativeItemId: string;
  allChecked: boolean;
  priceTypeOptions: any[];
  operatorOptions: any[];
  price: ItemPrice;
  quota: number;
  selectedItem: ItemParam | null;
  representativeChanged: boolean;
  hideClosedItem: boolean;
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLFormElement } }>
).extend({
  name: 'ItemList',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PriceInput,
    PercentInput,
    RangeInput,
    FripTable,
    ItemDetail,
  },
  props: {
    items: {
      type: Array,
    },
    havePlans: {
      type: Boolean,
    },
    state: {
      type: String,
      required: true,
    },
    inventoryTargetType: {
      type: String,
    },
    isFrip: {
      type: Boolean,
    },
    isSale: {
      type: Boolean,
    },
    isDigitalFrip: {
      type: Boolean,
    },
    commission: {
      type: Object,
    },
    bookingConfirmationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): ItemListData {
    return {
      rows: this.items as ItemParam[],
      checkedRows: [],
      isAdmin: !isHostAdmin(),
      optionDetailModal: false,
      optionPriceChangeModal: false,
      representativeItemId: '',
      representativeChanged: false,
      allChecked: false,
      priceTypeOptions: priceTypeOptions,
      operatorOptions: [
        {
          value: PriceOperator.PLUS,
          text: '높입니다.',
        },
        {
          value: PriceOperator.SUB,
          text: '낮춥니다.',
        },
      ],
      /**
       * 호드민에서 판매가만 올려도 내부적으로 정가도 같이 계산되어야함
       */
      price: {
        type: isHostAdmin() ? PriceType.ALL : PriceType.ALL,
        value: 0,
        operator: PriceOperator.PLUS,
      },
      quota: 0,
      selectedItem: this.items[0] as ItemParam,
      hideClosedItem: true,
    };
  },
  computed: {
    showingRows(): ItemParam[] {
      return this.hideClosedItem
        ? this.rows.filter(
            row =>
              !(
                row.status == ItemState.CLOSED ||
                row.status == ItemState.TERMINATED
              )
          )
        : this.rows;
    },
    quotaText(): string {
      const quotaState = this.state;
      let text = '';
      switch (quotaState) {
        case QuotaState.OFFLINE:
          text = '최대예약수량';
          break;
        case QuotaState.ONLINE:
          text = '수량한정판매';
          break;
        case QuotaState.SHIPPING:
          text = '재고수량';
          break;
        case QuotaState.RECRUITMENT_BY_ITEM:
          text = '모집인원';
          break;
        default:
          text = '판매상태';
          break;
      }
      return text;
    },
    quotaOption(): ColumnOption {
      return {
        text: this.quotaText,
        value: 'quota',
      };
    },
    itemDepth(): number {
      return this.itemOptions.length;
    },
    itemOptions(): ItemOptionParam[] {
      let maxOptionLength = this.rows[0].options.length;
      let index = 0;
      for (let i = 0; i < this.rows.length; ++i) {
        if (this.rows[i].options.length > maxOptionLength) {
          maxOptionLength = this.rows[i].options.length;
          index = i;
        }
      }
      return this.rows[index].options; // TODO: 수정필요
    },
    optionNames(): ColumnOption[] {
      return this.itemOptions.map((option, index) => {
        return {
          text: option.title,
          value: `${index + 1}depth`,
        };
      });
    },
    columns(): ColumnOption[] {
      const columns = [
        {
          text: '대표',
          value: 'representative',
        },
        ...this.optionNames,
        {
          text: '가격',
          value: 'price',
        },
      ];
      columns.push(this.quotaOption);
      columns.push({
        text: '상세설정',
        value: 'setting',
      });
      return columns;
    },
    representativeItem(): ItemParam {
      const index = this.rows.findIndex(row => row.representative === true);
      if (index >= 0) return this.rows[index];
      else
        return {
          absentFee: {
            type: this.commission.absent.type,
            charge: this.commission.absent.charge,
            bias: 0,
          },
          commission: {
            type: this.commission.default.type,
            charge: this.commission.default.charge,
            bias: this.commission.default.bias,
          },
          daysOfExpiration: 0,
          description: '',
          maximumPurchasableCount: 0,
          minimumQuota: 0,
          name: '',
          options: this.generateNewItemOptions(),
          paramId: `item${Math.random()}`,
          price: {
            currencyCode: CurrencyCode.KRW,
            discountRate: 0,
            retail: 0,
            sale: 0,
          },
          quota: 0,
          representative: false,
          salesType: SalesType.AGENCY,
          scheduledBySale: false,
          ticketCount: 1,
        };
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(newItems) {
        this.rows = newItems;
      },
    },
  },
  methods: {
    showDetailModal(paramId: string) {
      const item = this.rows.find(item => item.paramId === paramId);
      this.selectedItem = item ? item : null;

      this.optionDetailModal = true;
    },
    updateColumn(column: ColumnOption) {
      const index = this.columns.findIndex(c => c.value === column.value);
      this.columns[index].text = column.text;
      for (let i = 0; i < this.rows.length; i++) {
        this.rows[i].options[index - 1].title = this.columns[index].text;
      }
    },
    addItem() {
      console.log(this.representativeItem);
      this.rows.push({
        absentFee: {
          type: this.representativeItem.absentFee.type,
          charge: this.representativeItem.absentFee.charge,
          bias: 0,
        },
        commission: {
          type: this.representativeItem.commission.type,
          charge: this.representativeItem.commission.charge,
          bias: this.representativeItem.commission.bias,
        },
        daysOfExpiration: 0,
        description: '',
        maximumPurchasableCount: 0,
        minimumQuota: this.representativeItem.minimumQuota,
        name: '',
        options: this.generateNewItemOptions(),
        paramId: `item${Math.random()}`,
        price: {
          currencyCode: CurrencyCode.KRW,
          discountRate: 0,
          retail: 0,
          sale: 0,
        },
        quota: this.representativeItem.quota,
        representative: false,
        salesType:
          this.representativeItem.salesType === SalesType.AGENCY
            ? SalesType.AGENCY
            : SalesType.PURCHASE,
        scheduledBySale: false,
        ticketCount: 1,
      });
      const paramId = this.rows[this.rows.length - 1].paramId;
      this.$nextTick(() => {
        this.$refs[`${paramId}input`].focus();
      });
    },
    setItemName(paramId: string) {
      const index = this.rows.findIndex(row => row.paramId === paramId);
      if (index >= 0) {
        this.rows[index].name = this.rows[index].options
          .map(option => option.name)
          .filter(name => name !== '')
          .join('|');
      }
    },
    generateNewItemOptions(): ItemOptionParam[] {
      return this.itemOptions.map(option => {
        return {
          id: option.id,
          title: option.title,
          name: '',
        };
      });
    },
    async closeItem(id: string) {
      console.log('closeItem!');
      const targetItem = this.rows.find(row => row.id === id)!;
      if (this.representativeChanged) {
        this.$notify({
          title: '옵션 판매 중지 실패',
          message:
            '대표 옵션이 저장되지 않았습니다. 상품을 저장한 후 다시 시도해 주세요.',
          type: 'warning',
        });
      } else {
        await productItemStatusChangeService.closeItem(id);
        this.$set(targetItem, 'status', ItemState.CLOSED);
        this.$emit('refetch');
        this.$notify({
          title: '옵션이 판매 종료되었습니다.',
          message: '옵션이 판매 종료되었습니다.',
          type: 'success',
        });
      }
    },
    async terminateItem(id: string) {
      try {
        console.log('terminateItem!');
        const targetItem = this.rows.find(row => row.id === id)!;
        await productItemStatusChangeService.terminateItem(id);
        this.$set(targetItem, 'status', ItemState.TERMINATED);
        this.$emit('refetch');
        this.$notify({
          title: '옵션이 판매 종료되었습니다.',
          message: '옵션이 판매 종료되었습니다.',
          type: 'success',
        });
      } catch (error) {
        console.error(error);
      }
    },
    async openItem(id: string) {
      try {
        const targetItem = this.rows.find(row => row.id === id)!;
        await productItemStatusChangeService.openItem(id);
        this.$set(targetItem, 'status', ItemState.OPENED);
        this.$notify({
          title: '옵션이 재오픈 되었습니다.',
          message: '옵션이 재오픈 되었습니다.',
          type: 'success',
        });
        this.$emit('refetch');
      } catch (error) {
        console.error(error);
      }
    },
    closeDetailModal() {
      this.optionDetailModal = false;
    },
    showPriceChangeModal() {
      const selectedItems = this.rows.filter(row => row.checked);
      if (selectedItems.length === 0) {
        this.$modal.show({
          title: '',
          message: '선택한 옵션이 없습니다.',
          type: 'primary',
        });
      } else {
        this.optionPriceChangeModal = true;
      }
    },
    changeItemsPrice() {
      // 체크된 아이템의 가격을 변경합니다
      this.rows.forEach((item, index) => {
        if (item.checked) {
          if (this.price.type !== PriceType.RETAIL) {
            this.changeItemSale(index, this.price.value, this.price.operator);
          }
          if (this.price.type !== PriceType.SALE) {
            this.changeItemRetail(index, this.price.value, this.price.operator);
          }
        }
      });
      this.closePriceChangeModal();
    },
    itemStatusLabel(status: ItemState): StatusLabel {
      return getItemStatusLabel(status);
    },
    changeItemSale(index: number, value: number, operator: PriceOperator) {
      if (operator === PriceOperator.PLUS) {
        this.rows[index].price.sale += value;
      } else if (operator === PriceOperator.SUB) {
        this.rows[index].price.sale -= value;
      }
      if (this.rows[index].price.sale < 0) this.rows[index].price.sale = 0;
      console.log(this.rows[index].price.sale);
    },
    changeItemRetail(index: number, value: number, operator: PriceOperator) {
      if (operator === PriceOperator.PLUS) {
        this.rows[index].price.retail += value;
      } else if (operator === PriceOperator.SUB) {
        this.rows[index].price.retail -= value;
      }
      if (this.rows[index].price.retail < 0) this.rows[index].price.retail = 0;
    },
    closePriceChangeModal() {
      this.optionPriceChangeModal = false;
    },
    checkAllItems() {
      this.rows.forEach(item => {
        item.checked = this.allChecked;
      });
    },
    setRepresentativeItem(paramId: string) {
      console.log('id: ', paramId);
      this.$data.representativeChanged = true;
      this.rows.forEach(row => {
        if (row.paramId === paramId) {
          row.representative = true;
        } else {
          row.representative = false;
        }
      });

      // this.$emit('select', this.checkedCategories);
    },
    changeItem(param: ItemParam) {
      console.log('change Item!', param);
      const index = this.rows.findIndex(item => item.paramId === param.paramId);
      this.rows.splice(index, 1, param);
      this.closeDetailModal();
    },
    deleteOption(paramId: string) {
      console.log(paramId);
    },
    // TODO: 리팩토링 필요
    // TODO: 판매중인 아이템은 삭제 불가
    deleteSelectedItems() {
      const selectedItems = this.rows.filter(row => row.checked);
      const canDelete = selectedItems.every(
        row => !row.status || row.status === ItemState.EDITING
      );

      if (selectedItems.length === 0) {
        this.$modal.show({
          title: '',
          message: '선택한 옵션이 없습니다.',
          type: 'primary',
        });
      } else if (!canDelete) {
        this.$modal.show({
          title: '판매를 시작한 옵션은 삭제할 수 없습니다.',
          type: 'primary',
        });
      } else {
        this.$modal.show(
          {
            title: `선택한 ${selectedItems.length}개의 옵션을 삭제하시겠습니까?`,
            type: 'danger',
            showCancelButton: true,
          },
          () => {
            selectedItems.forEach(item => {
              const index = this.rows.findIndex(
                row => row.paramId === item.paramId
              );
              if (index >= 0) {
                this.rows.splice(index, 1);
              }
            });
          }
        );
      }
    },
  },
});
